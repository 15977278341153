import React, { Component } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";

import {Colors} from './Colors';

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 350px;
`;

const Input = styled.input`
    border:1px solid #e6e6e6;
    margin-bottom: 1rem;
    padding: 0.375rem 0.75rem;
`;

const TextArea = styled.textarea`
    border:1px solid #e6e6e6;
    margin-bottom: 1rem;
    font-family: Arial;
    padding: 0.375rem 0.75rem;
`;

const Submit = styled.button`
    background-color: ${Colors.red};
    padding: 1rem 3rem;
    color: white;
    font-family: 'Alte Haas Grotesk';
    border-radius: 0.25rem;
    border: 1px solid white;
    margin-bottom: 1rem;
`;

class Form extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            name: '',
            message: '',
            email: '',
            error: false,
            sent: false
        };
        this.recaptchaRef =  React.createRef();
        this.onChange = this.onChange.bind(this);
        this.updateName = this.updateName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
    }

    async onChange(value) {
        if (value) {
            const data = await fetch("/contact", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "message": this.state.message,
                    "email": this.state.email,
                    "name": this.state.name,
                    "recaptcha_code": value
                })
            });
            if (data.status === 200) {
                this.setState({
                    error: false,
                    sent: true,
                    name: '',
                    message: '',
                    email:''
                });
            } else if (data.status === 422) {
                this.setState({error: true});
            }
        }
        this.recaptchaRef.current.reset();
    }

    updateName(event) {
        this.setState({ name: event.target.value});
    }

    updateEmail(event) {
        this.setState({ email: event.target.value});
    }

    updateMessage(event) {
        this.setState({ message: event.target.value});
    }

    render() {
        return (
            <FormContainer>
                <h3>Stuur een bericht</h3>
                {this.state.error ? <div style={{color: 'red', paddingBottom: 15}}>Er is iets misgegaan. Controleer of alle velden zijn ingevuld en er een correct email adres is ingevuld.</div>: ""}
                {this.state.sent ? <div style={{color: 'green', paddingBottom: 15}}>Bericht verstuurd.</div>: ""}
                <Input type="text" placeholder="Naam" value={this.state.name} onChange={this.updateName}></Input>
                <Input type="text" placeholder="Email adres" value={this.state.email} onChange={this.updateEmail}></Input>
                <TextArea placeholder="Schrijf een bericht..." cols="30" rows="10" value={this.state.message} onChange={this.updateMessage}></TextArea>
                <Submit onClick={(e) => {
                    e.preventDefault(); 
                    this.recaptchaRef.current.execute()}
                    }>
                        Stuur Bericht
                </Submit>
                <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size="invisible"
                    sitekey="6LcrvckUAAAAAK9PyfUN83ov9HzP6TjnTWD2RC_B"
                    badge="inline"
                    onChange={this.onChange}
                />
            </FormContainer>
        )
    }
}

export default Form;