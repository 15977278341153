import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import GoogleMap from 'google-map-react';

import {Boxen} from './Boxen';
import Form from './Form';

import { Colors } from './Colors';


const Header = styled.header`
  width: 100vw;
  background: ${Colors.red};
  position: fixed;
`;

const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  font-family: 'Alte Haas Grotesk';
  justify-content: center;
  align-items: center;
`;

const Menu = styled.ul`
  list-style: none;
  display: none;
  color: white;
  margin: 5px;
  @media (min-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: block;
  @media (min-width: 768px){
    display: none;
  }
`;

const MobileMenuHamburger = styled.div`
  color: white;
  & > div {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
  }
`;

const MobileMenuList = styled.ul`
  list-style: none;
  position: fixed;
  margin: 0;
  padding: 0;
  padding-top: 15px;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: ${Colors.red};
`;

const MenuItem = styled.li`
  margin: 15px;
  display: block;
  text-transform: uppercase;
  @media (min-width: 768px){
    display: inline-block;
  }
`;

const MenuNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: 1.3em;
`;

const Logo = styled.img`
  height: 90px;
  transition: 0.4s;
  @media (min-width: 768px) {
    height: 150px;
  }
`;

const FirstParagraph = styled.div`
  background-image: url('/images/banner2_small.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
  @media (min-width: 768px) {
    background-image: url('/images/banner2.jpg');
  }
`;

const SecondParagraph = styled.div`
  margin-top: -70px;
  padding-bottom: 50px;
  @media (max-width: 900px) {
    & :first-child {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const ThirdParagraph = styled.div`
  background-color: #f6f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const FourthParagraph = styled.div`
  @media(max-width: 600px) {
    & #form {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Services = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const Map = styled.div`
  height: 40vh;
  margin-top: 50px;
  padding-bottom: 50px;
  background-color: ${Colors.red};
`;

const mapsSettings = {
  center: [51.453705, 5.794120],
  zoom: 13,
}

const Marker = () => <div 
  style={{
    width:30,
    height:30,
    left: '50%',
    top: '50%',
    // position: 'absolute',
    border: '2px solid ' + Colors.red,
    backgroundColor: Colors.red,
    color: Colors.red,
    borderRadius: '50% 50% 50% 0',
    transform: 'rotate(-45deg)',
    margin: '-30px 0 0 -15px',
  }}
  ><div style={{
    width: 12,
    height: 12,
    backgroundColor: 'white',
    left: '50%',
    top: '50%',
    position: 'relative',
    margin: '-6px 0 0 -6px',
    borderRadius: '50%'
  }}/>
</div>;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {mobileMenuOpen: false};

    // This binding is necessary to make `this` work in the callback
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.matchMedia("(min-width: 768px)").matches) {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        document.getElementById("logo").style.height = "60px";
      } else {
        document.getElementById("logo").style.height = "150px";
      }
    }
  }

  toggleMenu() {
    this.setState(state => ({
      mobileMenuOpen: !state.mobileMenuOpen
    }));
  }

  render() {
    const menu = (
      <>
        <MenuItem><a href="#home">Home</a></MenuItem>
        <MenuItem><a href="#units">Units</a></MenuItem>
        <MenuItem><a href="#contact">Contact</a></MenuItem>
      </>
    );

    return (
      <Router>
        <Header>
          <Container>
            <Logo src="/images/logo.png" alt="" id="logo"/>
            <MenuNav>
              <Menu>
               {menu}
              </Menu>
              <MobileMenu>
                <MobileMenuHamburger onClick={this.toggleMenu}>
                  {!this.state.mobileMenuOpen? (
                    <>
                      <div></div>
                      <div></div>
                      <div></div>
                    </>
                  ):(<span style={{fontSize: "2em"}}>&times;</span>)}
                </MobileMenuHamburger>
                {this.state.mobileMenuOpen ? (
                  <MobileMenuList>
                    {menu}
                  </MobileMenuList>
                ): ""}
              </MobileMenu>
            </MenuNav>
          </Container>
        </Header>
        <FirstParagraph id="home">
          <Container>
            <div style={{color: 'white'}}>
              <h1>Verwarmde en beveiligde units voor (tijdelijke) opslag van uw spullen</h1>
              <h3>Voor particulieren en bedrijven</h3>
            </div>
          </Container>
        </FirstParagraph>
        <SecondParagraph>
          <Container>
            <Services>
              <ServiceItem>
                <img src="images/image1.jpg" style={{width: '300px'}} alt=""/>
                <h3>Gebruik aanhanger inbegrepen</h3>
              </ServiceItem>
              <ServiceItem>
                <img src="images/image2.jpg" style={{width: '300px'}} alt=""/>
                <h3>Binnen laden en lossen</h3>
              </ServiceItem>
              <ServiceItem>
                <img src="images/image3.jpg" style={{width: '300px'}} alt=""/>
                <h3>Units met diverse afmetingen</h3>
              </ServiceItem>
            </Services>
          </Container>
        </SecondParagraph>
        <ThirdParagraph id="units">
          <Container>
            <Boxen/>
          </Container>
        </ThirdParagraph>
        <FourthParagraph id="contact">
          <Container>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems:'center'}}>
              <h1>Contact</h1>
              <div id="form" style={{display: 'flex', justifyContent:'space-evenly', width: '100%'}}>
                <Form/>
                <div>
                  <h3>Contactinformatie</h3>
                  <p>
                    <b>Adres:</b> <br/>
                    Gerard Bildersstraat 11B <br/>
                    5753 DE Deurne
                  </p>
                  <p>
                    <b>Telefoonnummer:</b> <br/>
                    06-46744174
                  </p>
                  <p>
                    <b>Email:</b> <br/>
                    <a href="mailto:info@miniopslagdeurne.nl" style={{color: 'black'}}>info@miniopslagdeurne.nl</a>
                  </p>
                  <p>
                    <b>Facebook:</b> <br/>
                    <a href="https://www.facebook.com/miniopslagdeurne.nl/" style={{color: 'black'}}>miniopslagdeurne.nl</a>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </FourthParagraph>
        <Map>
          <GoogleMap
            bootstrapURLKeys={{key: 'AIzaSyB_mu7d-Y27Y4W7UNMpjp-3JD60bPwqDIQ'}}
            defaultCenter={mapsSettings.center}
            defaultZoom={mapsSettings.zoom}
            options={{scrollwheel: false}}
          >
            <Marker
              lat={mapsSettings.center[0]} 
              lng={mapsSettings.center[1]}
            />
          </GoogleMap>
          <p style={{color: 'white', textAlign: 'center'}}>&copy; Mini Opslag Deurne 2019</p>
        </Map>
     </Router>
    );
  }
}

export default App;
